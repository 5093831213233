import React, { useState, useRef } from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import "../../../styles/blocks/search.scss";

const SearchForm = () => {
  const searchSlugQuery = useStaticQuery(graphql`
    query searchTemplateSlug {
      wpTemplate(template_reference: {referenceTemplate: {eq: "search"}}) {
        slug
      }
    }
  `);
  const [isInputShown, showInput] = useState(false);
  const form = useRef(null);
  const input = useRef(null);
  const submit = () => {
    if (!input.current.value.trim().length) {
      //empty search
      return;
    }
    navigate("/"+searchSlugQuery.wpTemplate.slug, {
      state: {
        query: input.current.value.trim()
      }
    });
  };
  return (
    <form
      ref={ form }
      onBlur={ (e) => {
        if (!form || !form.current) {
          return;
        }
        if (!form.current.contains(e.relatedTarget) && isInputShown) {
          showInput(false);
        }
      }}
      className={ "search " + ((isInputShown) ? "expanded":"collapsed") }
    >
      <input
        ref={ input }
        type="search"
        placeholder="Rechercher"
        onKeyUp={ (e) => {
          if (e.keyCode === 13) {
            submit();
          }
        }}
      />
      <button onClick={ (e) => {
        e.preventDefault();
        showInput(true);
      }}>
        <span className="sr-only">Afficher le champ de recherche</span>
      </button>
      <button onClick={ (e) => {
        e.preventDefault();
        submit();
      }}>
        <span className="sr-only">Rechercher</span>
      </button>
    </form>
  );
};
export default SearchForm;
